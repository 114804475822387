// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Cookies from "js.cookie"
import * as intlTelInput from 'intl-tel-input';
import "../src/javascripts/tel-utils";

export default class extends Controller {
  static targets = [
    "loaded",
    "loadError",
    "ajaxError",
    "emailButton",
    "emailForm",
    "emailInput",
    "emailError",
    "emailSubmit",
    "phoneButton",
    "phoneForm",
    "phoneInput",
    "phoneError",
    "phoneSubmit"
  ]

  initialize() {
    this.telInput = intlTelInput(this.phoneInputTarget, {
      initialCountry: "GB",
      preferredCountries: ["US", "GB", "AU", "SE", "SG", "FR", "SW", "CA", "IE", "BE", "NL", "HK", "DE", "AT", "MO", "IN", "IT", "PT", "LU", "NZ", "JP", "ZA", "AE"],
      autoPlaceholder: "aggressive",
      utilsScript: "../src/javascripts/tel-utils",
    })
    this.phoneInputTarget.addEventListener("open:countrydropdown", function() {
       this.phoneInputTarget.classList.add("dropdown-open")
    }.bind(this))
    this.phoneInputTarget.addEventListener("close:countrydropdown", function() {
      this.phoneInputTarget.classList.remove("dropdown-open")
    }.bind(this))
    this.phoneInputTarget.addEventListener("countrychange", function() {
      this.clearPhoneForm()
    }.bind(this))
  }

  connect() {
    this.showPhoneForm()

    // start time to capture load errors
    this.branchTimer = setTimeout(function() {
      this.showLoadError()
    }.bind(this), 2000)

    //
    branch.init(this.data.get("key"), function(err, data) {
      if (this.branchTimer) {
        clearTimeout(this.branchTimer)
      }

      if (!err) {
        this.checkBranchData(data)
      } else {
        this.showLoadError()
      }
    }.bind(this));
  }

  //
  //
  //
  checkBranchData(data) {
    // from data
    this.code = data.data_parsed.code;

    // try from previous session
    if (!this.code) {
      this.code = Cookies.get('last_code');
    }

    // handle
    if (this.code) {
      Cookies.set('last_code', this.code);
      this.handleBranch(this.code)
    } else {
      this.showLoadError()
    }
  }
  handleBranch(code) {
    // with data, ask our internal API for any details!
    fetch('/api/lookup', {
      method: 'post',
      body: JSON.stringify({ code: code }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then(function(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if (data.deeplink_url) {
        /// do nothing - all ok to go
      } else {
        this.showLoadError()
      }
    }.bind(this)).catch(function(err) {
      console.log(err)
      this.showLoadError()
    }.bind(this))
  }

  //
  //
  //
  showPhoneForm() {
    this.formType = "phone"

    this.phoneButtonTarget.classList.add("active")
    this.emailButtonTarget.classList.remove("active")

    this.phoneFormTarget.classList.remove("hidden")
    this.emailFormTarget.classList.add("hidden")
  }
  clearPhoneForm() {
    this.phoneInputTarget.value = ""
    this.clearPhoneErrors()
  }
  clearPhoneErrors() {
    this.phoneFormTarget.classList.remove("is-invalid")
  }
  validatePhoneInput(e) {
    let enabled = this.phoneInputTarget.value.length > 0
    this.phoneSubmitTarget.toggleAttribute("disabled", !enabled)

    if (enabled && e.key === "Enter") {
      this.submitPhoneForm()
    }
  }
  validatePhoneForm() {
    let form = this.phoneFormTarget
    let input = this.phoneInputTarget
    let value = input.value
    let error = this.phoneErrorTarget

    //
    // check other general errors
    var validation = this.telInput.getValidationError();
    switch(validation) {
      case intlTelInputUtils.validationError.TOO_SHORT:
        error.innerHTML = "The phone number entered is too short."
        form.classList.add("is-invalid")

        return false
      case intlTelInputUtils.validationError.TOO_LONG:
        error.innerHTML = "The phone number entered is too long."
        form.classList.add("is-invalid")

        return false
      case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        error.innerHTML = "The country code entered is invalid."
        form.classList.add("is-invalid")

        return false
      case intlTelInputUtils.validationError.NOT_A_NUMBER:
        if (value.length){
          error.innerHTML = "This phone number entered is not valid."
          form.classList.add("is-invalid")

          return false
        }
      default:
        // we should have covered all the scenarios, but just in case, we check for valid mobile number type
        var numberType = this.telInput.getNumberType()
        if (numberType !== intlTelInputUtils.numberType.MOBILE && value.length) {
          error.innerHTML = "The number entered is not a mobile number."
          form.classList.add("is-invalid")

          return false
        }
    }

    // all validation checks have passed, so we return true
    return value.length > 0
  }
  submitPhoneForm() {
    const valid = this.validatePhoneForm()
    if (valid) {
      // add loading status
      this.phoneInputTarget.blur()
      this.phoneFormTarget.classList.add("transition-sending")

      const number = this.telInput.getNumber()
      fetch('/api/sms', {
        method: 'post',
        body: JSON.stringify({ code: this.code, to: number }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      }).then(function(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
      }).then(function(response) {
        // all ok (for 2 seconds)
        setTimeout(function() {
          this.phoneFormTarget.classList.add("transition-sent")
          setTimeout(function() {
            this.phoneFormTarget.classList.remove("transition-sending")
            this.phoneFormTarget.classList.remove("transition-sent")
            this.phoneInputTarget.value = ""
            this.phoneInputTarget.focus()
          }.bind(this), 3000);
        }.bind(this), 750);
      }.bind(this)).catch(function(err) {
        this.phoneFormTarget.classList.remove("transition-sending")
        this.showAjaxError()
      }.bind(this))
    }
  }

  //
  //
  //
  showEmailForm() {
    this.formType = "email"

    this.phoneButtonTarget.classList.remove("active")
    this.emailButtonTarget.classList.add("active")

    this.phoneFormTarget.classList.add("hidden")
    this.emailFormTarget.classList.remove("hidden")
  }
  clearEmailForm() {
    this.emailInputTarget.value = ""
    this.clearEmailErrors()
  }
  clearEmailErrors() {
    this.emailFormTarget.classList.remove("is-invalid")
  }
  validateEmailInput(e) {
    let enabled = this.emailInputTarget.value.length > 0
    this.emailSubmitTarget.toggleAttribute("disabled", !enabled)

    if (enabled && e.key === "Enter") {
      this.submitEmailForm()
    }
  }
  validateEmailForm() {
    let form = this.emailFormTarget
    let input = this.emailInputTarget
    let value = input.value
    let error = this.emailErrorTarget

    //
    if (value && value.length && !value.includes("@")) {
      error.innerHTML = "The email address entered is not valid."
      form.classList.add("is-invalid")

      return false
    }

    // all validation checks have passed, so we return true
    return value.length > 0
  }
  submitEmailForm() {
    const valid = this.validateEmailForm()
    if (valid) {
      // add loading status
      this.emailInputTarget.blur()
      this.emailFormTarget.classList.add("transition-sending")

      const email = this.emailInputTarget.value
      fetch('/api/email', {
        method: 'post',
        body: JSON.stringify({ code: this.code, to: email }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      }).then(function(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
      }).then(function(response) {
        // all ok (for 2 seconds)
        setTimeout(function() {
          this.emailFormTarget.classList.add("transition-sent")
          setTimeout(function() {
            this.emailFormTarget.classList.remove("transition-sending")
            this.emailFormTarget.classList.remove("transition-sent")
            this.emailInputTarget.value = ""
            this.emailInputTarget.focus()
          }.bind(this), 3000);
        }.bind(this), 750);
      }.bind(this)).catch(function(err) {
        this.emailFormTarget.classList.remove("transition-sending")
        this.showAjaxError()
      }.bind(this))
    }
  }

  //
  //
  //
  showLoaded() {
    this.ajaxErrorTarget.classList.add("hidden")
    this.loadErrorTarget.classList.add("hidden")
    this.loadedTarget.classList.remove("hidden")
  }
  showLoadError() {
    window.location.href = "/download"
  }
  showAjaxError() {
    this.loadedTarget.classList.add("hidden")
    this.loadErrorTarget.classList.add("hidden")
    this.ajaxErrorTarget.classList.remove("hidden")
  }
}
